import { useEffect } from "react";
import { useGetUserInfo } from "api/user/useGetUserInfo";
import { ReactComponent as CloseIcon } from "assets/close.svg";
import { Button } from "components/Button/Button";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useModal } from "../Modal/useModal";
const LOCAL_STORAGE_KEY = "updateInfoNotificationDismissed";
const DISMISSAL_DURATION_DAYS = 30; // Show notification again after 30 days
function isDismissalExpired(): boolean {
  const dismissedData = localStorage.getItem(LOCAL_STORAGE_KEY);
  if (!dismissedData) return true;
  try {
    const { expiry } = JSON.parse(dismissedData);
    return new Date().getTime() > expiry;
  } catch {
    return true;
  }
}
export function UpdateInfoNotification() {
  const { t } = useModifiedTranslation();
  const { data, loading, error } = useGetUserInfo();
  const {
    Modal,
    onOpen,
    modalProps,
    contentProps: baseContentProps,
  } = useModal<{
    message: string;
    link: string;
  }>();
  // Combine the content props with onClose
  const contentProps = {
    ...baseContentProps,
    onClose: modalProps.onClose,
  };
  useEffect(() => {
    if (loading || error || !data?.createdDate) return;
    // Check if dismissal is still valid
    if (!isDismissalExpired()) return;
    const creationDate = new Date(data.createdDate);
    if (isNaN(creationDate.getTime())) {
      console.warn("Invalid creation date received:", data.createdDate);
      return;
    }
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 2);
    if (creationDate < oneYearAgo) {
      onOpen({
        message: t("onboardingPage.welcomeInfoKyc.message"),
        link: "/update-info",
      });
    }
  }, [data, loading, error, onOpen, t]);
  const handleClose = () => {
    modalProps.onClose();
    // Store dismissal with expiration
    const expiry = new Date();
    expiry.setDate(expiry.getDate() + DISMISSAL_DURATION_DAYS);
    localStorage.setItem(
      LOCAL_STORAGE_KEY,
      JSON.stringify({
        dismissed: true,
        expiry: expiry.getTime(),
      })
    );
  };
  return (
    <Modal {...modalProps}>
      <div className="flex flex-col">
        <div className="flex justify-between items-center p-4 border-b border-gray-200">
          <h2 className="text-lg font-medium text-gray-900">
            {t("onboardingPage.welcomeInfoKyc.title")}
          </h2>
          <button
            onClick={handleClose}
            className="p-1 text-gray-400 hover:text-gray-500 hover:bg-gray-100 rounded-full focus:ring-2 focus:ring-primary-500 focus:outline-none"
            aria-label={t("common.close")}
          >
            <CloseIcon className="w-5 h-5" />
          </button>
        </div>
        <div className="p-4 max-w-lg">
          <p className="mb-4 text-sm text-gray-600">{contentProps.message}</p>
          <div className="flex justify-start">
            <Button
              onClick={() => {
                handleClose();
                window.location.href = contentProps.link;
              }}
            >
              {t("onboardingPage.welcomeInfoKyc.button")}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
