import { useGetUserInfo } from "api/user/useGetUserInfo";
import { ReactComponent as CopyIcon } from "assets/copy-document.svg";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { toast } from "react-toastify";

export function ReferralModalContent() {
  const { t } = useModifiedTranslation();
  const { data: userInfo } = useGetUserInfo();

  const hasReferralCode = Boolean(userInfo?.referralCode);
  const referralLink = hasReferralCode
    ? `https://kvarnx.com/rekisteroityminen/${userInfo?.referralCode}`
    : "";

  const numberOfReferredFriends = userInfo?.numberOfReferredFriends ?? 0;

  const rewardFromFriends = Number(numberOfReferredFriends) * 50 || 0;

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(referralLink);
      toast.success(t("referral.copySuccess"), {
        autoClose: 3000,
      });
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <div className="p-6 mx-auto space-y-6 max-w-3xl">
      <div className="space-y-4 text-left">
        <h1 className="text-2xl font-bold text-kvarn-dark-green">
          {t("referral.title")}
        </h1>

        <p className=" text-kvarn-gray">{t("referral.commission")}</p>

        <div className="space-y-4">
          <div>
            <h3 className="mb-2 text-xl font-bold text-kvarn-dark-green">
              {t("referral.youGet")}
            </h3>
            <p className="text-kvarn-gray">{t("referral.youEarn")}</p>
          </div>

          <div>
            <h3 className="mb-2 text-xl font-bold text-kvarn-dark-green">
              {t("referral.friendsGet")}
            </h3>
            <p className="text-kvarn-gray">{t("referral.friendsDiscount")}</p>
          </div>
        </div>
      </div>

      <div className="space-y-2">
        <p className="text-kvarn-gray">{t("referral.invitationLink")}</p>
        {hasReferralCode ? (
          <>
            <div className="flex gap-2">
              <input
                type="text"
                readOnly
                value={referralLink}
                className="flex-1 p-3 text-sm text-kvarn-dark-green bg-white rounded border border-kvarn-dark-green"
              />
              <button
                onClick={handleCopyLink}
                className="p-3 text-kvarn-dark-green hover:bg-kvarn-light-green rounded border border-kvarn-dark-green transition-colors"
              >
                <CopyIcon className="w-5 h-5 transition-transform active:scale-90" />
              </button>
            </div>
          </>
        ) : (
          <p className="p-3 text-sm text-kvarn-gray bg-white rounded border border-kvarn-dark-green">
            {t("referral.noReferralCode")}
          </p>
        )}
      </div>

      {/* <button
        onClick={handleCopyLink}
        className="py-4 px-6 w-full font-medium text-kvarn-light-green bg-kvarn-dark-green rounded-lg transition-colors hover:bg-[#003f48]"
      >
        {t("referral.share")}
      </button> */}

      <p className="mx-auto text-sm text-kvarn-gray">
        {t("referral.smallPrint")}
      </p>

      <div className="p-4 sm:p-6 space-y-4 rounded-lg bg-[#f8faf9]">
        <h2 className="text-xl font-semibold text-center text-kvarn-dark-green">
          {t("referral.commissionsTitle")}
        </h2>
        <p className="text-center text-kvarn-gray">
          {t("referral.commissionsDescription")}
        </p>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 sm:gap-4 text-center">
          <div className="space-y-1">
            <p className="text-xl sm:text-2xl font-bold text-kvarn-dark-green">
              {t("referral.friendsJoined")}
            </p>
            <p className="text-kvarn-gray">{numberOfReferredFriends}</p>
          </div>
          <div className="space-y-1">
            <p className="text-xl sm:text-2xl font-bold text-kvarn-dark-green">
              {t("referral.receivedCommissions")}
            </p>
            <p className="text-kvarn-gray">{rewardFromFriends} USDC</p>
          </div>
        </div>
      </div>
      <a
        href={t("onboardingPage.welcomeInfo.termsOfServiceUrl")}
        target="_blank"
        rel="noopener noreferrer"
        className="block text-center text-kvarn-link-green transition-colors hover:text-[#0d6d5c]"
      >
        {t("referral.terms")}
      </a>
    </div>
  );
}
