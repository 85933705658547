import { HTMLAttributes } from "react";
import { ReactComponent as FileIcon } from "assets/document-text.svg";

interface DownloadableDocumentProps extends HTMLAttributes<HTMLAnchorElement> {
  label: string;
  url: string;
  icon?: React.ReactNode;
}

export const DownloadableDocument = ({
  label,
  url,
  icon,
  ...anchorAttributes
}: DownloadableDocumentProps) => (
  <a target="_blank" rel="noopener noreferrer" {...anchorAttributes} href={url}>
    <div className="flex justify-between text-kvarn-dark-green stroke-primary-300">
      <div className="text-base underline underline-offset-2">{label}</div>
      {icon || <FileIcon className="w-6 h-6 stroke-primary-300" />}
    </div>
  </a>
);
