import { gql, useQuery } from "@apollo/client";

export const TRANSACTIONS_QUERY = gql`
  query GetBuyData($portfolioId: Long, $orderStatus: String) {
    portfolio(id: $portfolioId) {
      id
      currency {
        securityCode
      }
      portfolioReport(
        adjustPositionsBasedOnOpenTradeOrders: false
        calculateExpectedAmountBasedOpenTradeOrders: false
      ) {
        portfolioId
        accountBalanceAdjustedWithOpenTradeOrders: accountBalance
      }
      tradeOrders(typeCodes: ["WD"], orderStatus: $orderStatus) {
        id
        tradeAmount
      }
    }
  }
`;

export const OPEN_TRANSACTION_QUERY = gql`
  query GetBuyData($portfolioId: Long) {
    portfolio(id: $portfolioId) {
      id
      currency {
        securityCode
      }
      tradeOrders(typeCodes: ["B"], orderStatus: "5") {
        id
        tradeAmount
      }
    }
  }
`;

interface PortfolioTransactionsQuery {
  portfolio: {
    currency: {
      securityCode: string;
    };
    portfolioReport: {
      accountBalanceAdjustedWithOpenTradeOrders: number;
    };
    tradeOrders: Array<{
      id: string;
      tradeAmount: number;
    }>;
  };
}

export const useGetBuyDataWithOpenOrders = (
  portfolioId: string | undefined
) => {
  const transactionsQueryResult = useQuery<PortfolioTransactionsQuery>(
    TRANSACTIONS_QUERY,
    {
      variables: { portfolioId, orderStatus: "5" },
      fetchPolicy: "network-only",
    }
  );

  const openTransactionQueryResult = useQuery<PortfolioTransactionsQuery>(
    OPEN_TRANSACTION_QUERY,
    {
      variables: { portfolioId },
      fetchPolicy: "network-only",
    }
  );

  const transactionQueryResultInExecutionState =
    useQuery<PortfolioTransactionsQuery>(TRANSACTIONS_QUERY, {
      variables: { portfolioId, orderStatus: "6" },
      fetchPolicy: "network-only",
    });

  let totalWithdrawals = 0;
  let totalBuys = 0;
  let totalWithDrawalsInExecutionState = 0;

  if (transactionsQueryResult.data) {
    totalWithdrawals =
      transactionsQueryResult.data.portfolio.tradeOrders.reduce(
        (acc, order) => acc + order.tradeAmount,
        0
      );
  }

  if (openTransactionQueryResult.data) {
    totalBuys = openTransactionQueryResult.data.portfolio.tradeOrders.reduce(
      (acc, order) => acc + order.tradeAmount,
      0
    );
  }

  if (transactionQueryResultInExecutionState.data) {
    totalWithDrawalsInExecutionState =
      transactionQueryResultInExecutionState.data.portfolio.tradeOrders.reduce(
        (acc, order) => acc + order.tradeAmount,
        0
      );
  }

  const loading =
    transactionsQueryResult.loading || openTransactionQueryResult.loading;
  const error =
    transactionsQueryResult.error || openTransactionQueryResult.error;
  const data = transactionsQueryResult.data && {
    availableCash:
      transactionsQueryResult.data.portfolio.portfolioReport
        .accountBalanceAdjustedWithOpenTradeOrders -
      totalWithdrawals -
      totalBuys -
      totalWithDrawalsInExecutionState,
    currency: transactionsQueryResult.data.portfolio.currency.securityCode,
  };

  return {
    loading,
    error,
    data,
    totalWithdrawals,
    totalBuys,
    totalWithDrawalsInExecutionState,
    refetch: () => {
      transactionsQueryResult.refetch();
      openTransactionQueryResult.refetch();
    },
  };
};
