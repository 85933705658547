import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import {
  ONBOARDING_PROCESS_KEYS,
  OnboardingProcessKey,
} from "views/onboarding/helpers";
import iphoneImage from "../../../assets/images/kvarnx-phone.png";
import logo from "../../../assets/images/logo_onboarding.png";

interface SelectOnboardingTypeProps {
  selectOnboardingProcessType: (key: OnboardingProcessKey) => void;
}

const ProcessKeyToTranslationKey: Record<OnboardingProcessKey, string> = {
  [ONBOARDING_PROCESS_KEYS.PRIVATE]: "private",
  [ONBOARDING_PROCESS_KEYS.BUSINESS]: "company",
};

export const SelectOnboardingType = ({
  selectOnboardingProcessType,
}: SelectOnboardingTypeProps) => {
  const { t } = useModifiedTranslation();

  const options = Object.values(ONBOARDING_PROCESS_KEYS).map((key) => {
    return {
      key,
      buttonLabel: t(`onboardingPage.${ProcessKeyToTranslationKey[key]}`),
    };
  });

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <div className="container py-6 px-4 mx-auto max-w-6xl">
        <img src={logo} alt="Kvarn X Logo" className="w-44 h-auto" />
      </div>
      <div className="flex flex-grow items-center">
        <div className="container flex flex-col md:flex-row items-center px-4 mx-auto max-w-6xl">
          <div className="mb-8 md:mb-0 md:w-1/2">
            <h1 className="mb-8 text-4xl font-bold text-[#002E1F]">
              {t("onboardingPage.welcome")}
            </h1>
            <p className="mb-6 text-lg text-gray-700">
              {t("onboardingPage.selectCustomerType")}
            </p>
            <div className="flex flex-col gap-4 max-w-sm">
              {options.map((option) => (
                <button
                  key={option.key}
                  onClick={() => selectOnboardingProcessType(option.key)}
                  className="py-2 px-6 w-full text-lg font-medium bg-gray-50 hover:bg-gray-100 border transition-colors duration-200 text-[#002E1F] rounded-[32px] border-[#002E1F]"
                >
                  {option.buttonLabel}
                </button>
              ))}
            </div>
          </div>
          <div className="md:w-1/2">
            <img
              src={iphoneImage}
              alt="Kvarn X App Interface"
              className="mx-auto w-full max-w-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
