import { ReactComponent as DepositIcon } from "assets/deposit.svg";
import { UserMenu, Logo, PortfolioSelect } from "components";
import {
  TOTAL_INVESTMENTS_OPTION_ID,
  useGetPortfolioOptions,
} from "hooks/useGetPortfolioOptions";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useNavigateToPortfolioTab } from "./useNavigateToPortfolioTab";
import { useRedirectIfOnlyOnePortfolio } from "./useRedirectIfOnlyOnePortfolio";

export interface PortfolioOption {
  id: number;
  urlPrefix: string;
  label: string;
}

export const PortfolioNavigationHeader = () => {
  const { t } = useModifiedTranslation();
  const portfolioOptions = useGetPortfolioOptions();
  const { portfolioId } = useParams();
  const navigate = useNavigate();
  const navigateToPortfolioTab = useNavigateToPortfolioTab();
  useRedirectIfOnlyOnePortfolio();

  const onPortfolioChange = (selectedOption: PortfolioOption) => {
    navigateToPortfolioTab(selectedOption.urlPrefix);
  };

  const currentPortfolio = portfolioId
    ? parseInt(portfolioId, 10)
    : TOTAL_INVESTMENTS_OPTION_ID;

  const handleDepositClick = () => {
    // Get the first actual portfolio (excluding total investments view)
    const firstActualPortfolio = portfolioOptions.find(
      (option) => option.id !== TOTAL_INVESTMENTS_OPTION_ID
    );

    if (
      portfolioId &&
      parseInt(portfolioId, 10) !== TOTAL_INVESTMENTS_OPTION_ID
    ) {
      // If we're in a specific portfolio view (not total investments)
      navigate(`/portfolio/${portfolioId}/transfers`);
    } else if (firstActualPortfolio) {
      // If we're in total investments view or no portfolio selected, go to first actual portfolio
      navigate(`/portfolio/${firstActualPortfolio.id}/transfers`);
    } else {
      // Fallback if no actual portfolios exist
      navigate("/overview");
    }
  };

  // redirect to root when portfolioId does not match available portfolios
  if (
    currentPortfolio !== TOTAL_INVESTMENTS_OPTION_ID &&
    !portfolioOptions.some((option) => option.id === currentPortfolio)
  ) {
    return <Navigate to="/" replace />;
  }

  return (
    <div className="z-20 py-4 px-2 bg-center bg-no-repeat bg-cover sm:shadow-md bg-[url('/public/ylapalkki_tausta.png')]">
      <div className="container flex flex-row gap-2 justify-between items-center mx-auto">
        <Logo />

        <button
          className="hidden sm:flex gap-2 items-center py-2 px-4 font-bold text-kvarn-dark-green bg-kvarn-light-green rounded-full transition hover:bg-kvarn-light-green/80"
          onClick={handleDepositClick}
        >
          <DepositIcon className="text-lg" />
          {t("navTab.tabs.deposit")}
        </button>

        <div className="flex gap-2 sm:gap-4 items-center">
          <div className="flex-shrink-0 sm:min-w-[350px]">
            {portfolioOptions.length > 0 ? (
              <div className="overflow-hidden overflow-ellipsis rounded-full border border-gray-400 max-w-[120px] sm:max-w-[150px] md:max-w-[350px]">
                <PortfolioSelect
                  portfolioOptions={portfolioOptions}
                  portfolioId={currentPortfolio}
                  onChange={onPortfolioChange}
                  color="dark"
                />
              </div>
            ) : (
              <div />
            )}
          </div>
          <div className="flex gap-x-2 items-center">
            <UserMenu />
          </div>
        </div>
      </div>
    </div>
  );
};
