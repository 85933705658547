import React, { useEffect } from "react";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export function ZimplerCallbackHandler() {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useModifiedTranslation();

  useEffect(() => {
    const path = location.pathname;

    if (path.includes("/zimpler/success")) {
      toast.success(t("messages.depositSuccess"), { autoClose: 3000 });
      navigate("/");
      return;
    }

    if (path.includes("/zimpler/failure")) {
      toast.error(t("messages.depositFail"), { autoClose: 5000 });
      navigate("/");
      return;
    }

    if (path.includes("/zimpler/close")) {
      toast.info(t("moneyModal.depositFail"), { autoClose: 3000 });
      navigate("/");
      return;
    }
  }, [location, navigate, t]);

  return null;
}
