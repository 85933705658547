import { lazy } from "react";
import { PortfolioGuard, TranslationText } from "components";
import { MainLayout } from "layouts/MainLayout/MainLayout";
import { NavTabRoutes } from "layouts/NavTabLayout/NavTab/NavTabRoutes";
import { NavTabPath } from "layouts/NavTabLayout/NavTab/types";
import { PortfolioNavigationHeaderLayout } from "layouts/PortfolioNavigationHeaderLayout/PortfolioNavigationHeaderLayout";
import { Navigate, useRoutes } from "react-router-dom";
import { usePushNotifications } from "utils/mobile";
import { NotFoundView } from "views/notFoundView/notFoundView";
import { PortfolioRoutes } from "./portfolio/routes";
import { authUserMainRoutes } from "../authUser/routes";

const Overview = lazy(() =>
  import("./overview").then((module) => ({ default: module.OverviewPage }))
);
// const Trading = lazy(() =>
//   import("./trading").then((module) => ({ default: module.TradingPage }))
// );
// const Holdings = lazy(() =>
//   import("./holdings").then((module) => ({ default: module.HoldingsPage }))
// );
const Holding = lazy(() =>
  import("./holdings/[holdingId]").then((module) => ({
    default: module.HoldingPage,
  }))
);
// const Transactions = lazy(() =>
//   import("./transactions").then((module) => ({
//     default: module.TransactionsPage,
//   }))
// );
const TransactionDetails = lazy(() =>
  import("./transactions/[transactionId]").then((module) => ({
    default: module.TransactionDetailsPage,
  }))
);
// const Orders = lazy(() =>
//   import("./orders").then((module) => ({ default: module.OrdersPage }))
// );
const OrderDetails = lazy(() =>
  import("./orders/[orderId]").then((module) => ({
    default: module.OrderDetailsPage,
  }))
);
// const Documents = lazy(() =>
//   import("./documents").then((module) => ({ default: module.DocumentsPage }))
// );
// const Contact = lazy(() =>
//   import("./contact").then((module) => ({ default: module.ContactPage }))
// );

export const mainTabRoutes: NavTabPath[] = [
  {
    path: "overview",
    tabLabel: <TranslationText translationKey="navTab.tabs.overview" />,
    tabComponent: (
      <PortfolioGuard>
        <Overview />
      </PortfolioGuard>
    ),
    element: null,
  },
  // {
  //   path: "trading",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.trading" />,
  //   tabComponent: (
  //     <PortfolioGuard>
  //       <Trading />
  //     </PortfolioGuard>
  //   ),
  //   element: null,
  // },
  // {
  //   path: "holdings",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.holdings" />,
  //   tabComponent: (
  //     <PortfolioGuard>
  //       <Holdings />
  //     </PortfolioGuard>
  //   ),
  //   element: null,
  // },
  // {
  //   path: "transactions",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.transactions" />,
  //   tabComponent: (
  //     <PortfolioGuard>
  //       <Transactions />
  //     </PortfolioGuard>
  //   ),
  //   element: null,
  // },
  // {
  //   path: "orders",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.orders" />,
  //   tabComponent: (
  //     <PortfolioGuard>
  //       <Orders />
  //     </PortfolioGuard>
  //   ),
  //   element: null,
  // },
  // {
  //   path: "documents",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.documents" />,
  //   tabComponent: <Documents />,
  //   element: null,
  // },
  // {
  //   path: "contact",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.contact" />,
  //   tabComponent: <Contact />,
  //   element: null,
  // },
  // {
  //   path: "transfers",
  //   tabLabel: <TranslationText translationKey="navTab.tabs.transfers" />,
  //   tabComponent: <Contact />,
  //   element: null,
  // },
];

const linkedContactMainRoutes = [
  {
    path: "",
    element: <Navigate to="overview" replace />,
  },
  {
    path: "",
    element: <PortfolioNavigationHeaderLayout />,
    children: [
      {
        path: "*",
        element: <NavTabRoutes routes={mainTabRoutes} />,
      },
    ],
  },
  {
    path: "holdings/:holdingId",
    element: <Holding />,
  },
  {
    path: "transactions/:transactionId",
    element: <TransactionDetails />,
  },
  {
    path: "orders/:orderId",
    element: <OrderDetails />,
  },
];

export const userWithLinkedContactRoutes = [
  {
    path: "",
    element: <MainLayout />,
    children: [
      ...linkedContactMainRoutes,
      {
        path: "portfolio/:portfolioId/*",
        element: <PortfolioRoutes />,
      },
      ...authUserMainRoutes,
      {
        path: "*",
        element: <NotFoundView />,
      },
    ],
  },
];

export const UserWithLinkedContactRoutes = () => {
  // Push notifs count logins so we do it here to make sure we count times the user actually uses the app
  usePushNotifications();

  return useRoutes(userWithLinkedContactRoutes);
};
