import { SetStateAction } from "react";
import { SECURITY_CODE_TO_COINBASE_ID } from "api/securities";
import {
  SecurityCode,
  SecurityCodeWithoutTransferSupport,
} from "api/securities";
import { LIMIT_ORDER_ONLY_SECURITIES } from "api/securities";
import { TOptions, StringMap } from "i18next";

export const getDecimalPlaces = (
  numOrCode: number | string | undefined
): number => {
  if (typeof numOrCode === "string") {
    const eightDigitCodes = [
      "pepe",
      "bonk",
      "wen",
      "floki",
      "mog",
      "btt",
      "turbo",
      "shib",
    ];
    if (eightDigitCodes.includes(numOrCode.toLowerCase())) {
      return 8;
    }
  }

  if (typeof numOrCode === "string") {
    const fiveDigitCodes = ["hbar", "gala", "xdc", "shib"];
    if (fiveDigitCodes.includes(numOrCode.toLowerCase())) {
      return 5;
    }
  }

  if (typeof numOrCode === "string") {
    const fourDigitCodes = ["xrp", "1inch", "xlm"];
    if (fourDigitCodes.includes(numOrCode.toLowerCase())) {
      return 4;
    }
  }

  if (typeof numOrCode === "number" || typeof numOrCode === "string") {
    const num =
      typeof numOrCode === "string" ? parseFloat(numOrCode) : numOrCode;
    if (isNaN(num)) return 3;

    const numStr = num.toString();
    if (numStr.includes("e")) {
      const [base, exponent] = numStr.split("e");
      const exp = parseInt(exponent, 10);
      const decimalPlacesInBase = (base.split(".")[1] || "").length;
      return Math.max(0, decimalPlacesInBase - exp);
    } else {
      return (numStr.split(".")[1] || "").length;
    }
  }

  return 3;
};

export const validateUnitsTrade = (
  inputValue: string,
  setError: (value: SetStateAction<string>) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, options?: TOptions<StringMap> | undefined) => any,
  minMaxData?: {
    minimumSize: number;
    minSizeIncrement: number;
    maximumSize: number;
  }
) => {
  const value = parseFloat(inputValue);
  const minDecimalPlaces = getDecimalPlaces(minMaxData?.minSizeIncrement);
  const inputDecimalPlaces = getDecimalPlaces(parseFloat(inputValue));

  if (
    isNaN(value) || // should be a number
    !minMaxData?.minimumSize || // must be defined
    !minMaxData?.minimumSize || // must be defined
    inputDecimalPlaces > minDecimalPlaces // too many decimal places in input
  ) {
    setError(
      t("tradingModal.minBuyAmountError", {
        // Using this as a generic error
        minBuyAmount: minMaxData?.minimumSize,
        decimalPlaces: minDecimalPlaces,
      })
    );
    return;
  }

  // value too small
  if (value < minMaxData?.minimumSize) {
    setError(
      t("tradingModal.minBuyAmountError", {
        minBuyAmount: minMaxData?.minimumSize,
        decimalPlaces: minDecimalPlaces,
      })
    );
    // value too large
  } else if (value > minMaxData?.maximumSize) {
    setError(
      t("tradingModal.maxBuyAmountError", {
        maxBuyAmount: minMaxData?.maximumSize,
        decimalPlaces: minDecimalPlaces,
      })
    );
  } else {
    setError("");
  }
  return;
};

export const validateAmountTrade = (
  inputValue: string,
  setError: (value: React.SetStateAction<string>) => void,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  t: (key: string, options?: TOptions<StringMap> | undefined) => any,
  unitPrice: number,
  minMaxData?: { minimumSize: number; maximumSize: number }
) => {
  const value = parseFloat(inputValue);

  const unitsFromAmount = value / unitPrice;

  const minDecimalPlaces = (
    minMaxData?.minimumSize.toString().split(".")[1] || ""
  ).length;
  const inputDecimalPlaces = (inputValue.split(".")[1] || "").length;

  if (
    isNaN(value) || // inputValue must be a number
    value <= 0 || // inputValue must be positive
    !minMaxData ||
    !minMaxData?.minimumSize || // Min must be defined
    !minMaxData?.maximumSize || // Max must be defined
    isNaN(unitsFromAmount) || // result of division must be a number
    inputDecimalPlaces > minDecimalPlaces // too many decimal places in input
  ) {
    setError(
      t("tradingModal.invalidTradeAmountError", {
        // You might need to define this key in your translations
        minAmount: minMaxData?.minimumSize ?? 0 * unitPrice,
        maxAmount: minMaxData?.maximumSize ?? 0 * unitPrice,
        decimalPlaces: minDecimalPlaces,
      })
    );
    return;
  }

  // The computed units from the amount are too small
  if (unitsFromAmount < minMaxData.minimumSize) {
    setError(
      t("tradingModal.minTradeAmountError", {
        // Define or adjust this key in your translations
        minAmount: minMaxData.minimumSize * unitPrice,
        decimalPlaces: minDecimalPlaces,
      })
    );
  } else if (unitsFromAmount > minMaxData.maximumSize) {
    // The computed units from the amount are too large
    setError(
      t("tradingModal.maxTradeAmountError", {
        // Define or adjust this key in your translations
        maxAmount: minMaxData.maximumSize * unitPrice,
        decimalPlaces: minDecimalPlaces,
      })
    );
  } else {
    setError("");
  }
};

export const getCoinbaseName = (name: string): string => {
  return SECURITY_CODE_TO_COINBASE_ID[
    name as keyof typeof SECURITY_CODE_TO_COINBASE_ID
  ];
};
export const isLimitOrderOnly = (
  securityCode: SecurityCode | SecurityCodeWithoutTransferSupport
): boolean => {
  return LIMIT_ORDER_ONLY_SECURITIES.has(securityCode);
};
