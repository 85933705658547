import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Representee, useGetContactInfo } from "api/initial/useGetContactInfo";
import { ReactComponent as CheckIcon } from "assets/check.svg";
import { ReactComponent as MenuIcon } from "assets/view-list.svg";
import { Divider } from "components/Divider";
import { ModalHeader } from "components/Headings/ModalHeader";
import { ModalV2 } from "components/Modal/ModalDesignV2";
import { UserProfile } from "components/UserProfile/UserProfile";
import {
  useGetPortfolioOptions,
  TOTAL_INVESTMENTS_OPTION_ID,
} from "hooks/useGetPortfolioOptions";
import { useModifiedTranslation } from "hooks/useModifiedTranslation";
import {
  useGetContactIdData,
  SelectedContact,
} from "providers/ContactIdProvider";
import { FaUserCircle } from "react-icons/fa";
import { FaPeopleArrows } from "react-icons/fa";
import { GoGear } from "react-icons/go";
import { MdLogout } from "react-icons/md";
import { PiHandDeposit } from "react-icons/pi";
import { useNavigate, To, NavigateOptions } from "react-router";
import { useParams } from "react-router-dom";
import { keycloakService } from "services/keycloakService";
import { BUTTONS } from "testIds";
import { initials } from "utils/initials";
import { useModal } from "../Modal/useModal";
import { BankDepositModalContent } from "../MoneyModals/DepositModalContent/BankDepositModalContent";
import { DepositModalContent } from "../MoneyModals/DepositModalContent/DepositModalContent";
import { ReferralModalContent } from "../MoneyModals/ReferralModalContent/ReferralModalContent";

const RepresenteesList = ({
  contactData,
  representees,
  selectedContactId,
  setSelectedContact,
}: {
  contactData: { name: string; contactId: number };
  representees: Representee[];
  selectedContactId: number | string;
  setSelectedContact: (contact: SelectedContact) => void;
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div
        className={`flex gap-1 items-center p-2 w-full from-blue-50 to-white cursor-pointer ${
          representees.length > 0 ? "rounded-t-lg rounded-b-none" : "rounded-lg"
        } hover:bg-gray-100 transition duration-200`}
        style={{ background: "rgb(230, 238, 239)" }}
        onClick={() => {
          setSelectedContact({
            id: contactData?.contactId || "",
            contactId: contactData?.contactId || "",
            userName: contactData?.name || "-",
            initials: initials(contactData?.name),
          });
        }}
      >
        <span className="flex justify-center w-6">
          {contactData.contactId === selectedContactId && <CheckIcon />}
        </span>
        {contactData.name}
      </div>
      {representees.map((representee, idx) => {
        const isLast = idx === representees.length - 1;
        return (
          <div
            key={idx}
            className={`flex gap-1 items-center p-2 w-full from-blue-50 to-white cursor-pointer ${
              isLast ? "rounded-b-lg" : "rounded-none"
            } hover:bg-gray-100 transition duration-200`}
            style={{ background: "rgb(230, 238, 239)" }}
            onClick={() => {
              setSelectedContact({
                id: representee.id,
                contactId: representee.contactId,
                userName: representee.name,
                initials: initials(representee.name),
              });
            }}
          >
            <span className="flex justify-center w-6">
              {representee.id === selectedContactId && <CheckIcon />}
            </span>
            {representee.name}
          </div>
        );
      })}
    </div>
  );
};

export const UserMenu = () => {
  const { selectedContactId, setSelectedContactId, setSelectedContact } =
    useGetContactIdData();
  const { t } = useModifiedTranslation();
  const navigate = useNavigate();
  const { data: contactData } = useGetContactInfo();
  const portfolioOptions = useGetPortfolioOptions();
  const { portfolioId } = useParams();

  const {
    Modal,
    onOpen: onDepositModalOpen,
    modalProps: depositModalProps,
    contentProps: depositModalContentProps,
  } = useModal();

  // Bank transfer
  const { onOpen: onBankDepositModalOpen, modalProps: bankDepositModalProps } =
    useModal();

  // Profile
  const {
    onOpen: onUserProfileOpen,
    modalProps: userProfileModalProps,
    contentProps: userProfileModalContentProps,
  } = useModal();

  const { onOpen: onReferralModalOpen, modalProps: referralModalProps } =
    useModal();

  const navigateToTransfers = () => {
    // Get the first actual portfolio (excluding total investments view)
    const firstActualPortfolio = portfolioOptions.find(
      (option) => option.id !== TOTAL_INVESTMENTS_OPTION_ID
    );

    if (
      portfolioId &&
      parseInt(portfolioId, 10) !== TOTAL_INVESTMENTS_OPTION_ID
    ) {
      // If we're in a specific portfolio view (not total investments)
      navigate(`/portfolio/${portfolioId}/transfers`);
    } else if (firstActualPortfolio) {
      // If we're in total investments view or no portfolio selected, go to first actual portfolio
      navigate(`/portfolio/${firstActualPortfolio.id}/transfers`);
    } else {
      // Fallback if no actual portfolios exist
      navigate("/overview");
    }
  };

  const menuActions = {
    logout: () => keycloakService.onAuthLogout(),
    deposit: () => onDepositModalOpen(),
    finsharkdeposit: () => {
      navigateToTransfers();
    },
    bankdeposit: () => onBankDepositModalOpen(),
    withdraw: () => {
      navigateToTransfers();
    },
    profile: () => onUserProfileOpen(),
    process: (to: To, options?: NavigateOptions) => navigate(to, options),
    setSelectedContact: (contact: SelectedContact) => {
      setSelectedContact(contact);
      setSelectedContactId(contact.id);
      navigate("/overview", { replace: true });
    },
    referral: () => onReferralModalOpen(),
  };

  return (
    <>
      <Menu as="div" className="grid relative items-center">
        <Menu.Button>
          <div
            className="ml-0 md:ml-4 w-8 h-8 rounded cursor-pointer"
            data-testid={BUTTONS.MAIN_MENU}
          >
            <MenuIcon className="h-full text-white" />
          </div>
        </Menu.Button>

        <Transition
          enter="transition duration-100 ease-out"
          enterFrom="transform scale-95 opacity-0"
          enterTo="transform scale-100 opacity-100"
          leave="transition duration-75 ease-out"
          leaveFrom="transform scale-100 opacity-100"
          leaveTo="transform scale-95 opacity-0"
          as={Fragment}
        >
          <Menu.Items className="absolute top-full right-0 z-10 py-1 bg-white rounded-md ring-1 ring-black ring-opacity-5 shadow-lg origin-top-right focus:outline-none min-w-[300px]">
            <div className="p-4">
              <div className="flex flex-col justify-center items-center">
                <FaUserCircle className="w-14 h-14 text-kvarn-dark-green" />
                <ModalHeader
                  text={contactData?.name || ""}
                  className="text-md"
                />
              </div>
              <Divider />
              <div className="flex justify-evenly items-center">
                <div
                  className="flex flex-col gap-1 items-center p-2 hover:bg-gray-100 rounded-md cursor-pointer"
                  onClick={menuActions.profile}
                >
                  <GoGear className="w-5 h-5 text-gray-600" />
                  <span className="text-xs text-gray-700">
                    {t("userMenu.profile")}
                  </span>
                </div>
                <div
                  className="flex flex-col gap-1 items-center p-2 hover:bg-gray-100 rounded-md cursor-pointer"
                  onClick={menuActions.logout}
                >
                  <MdLogout className="w-5 h-5 text-gray-600" />
                  <span className="text-xs text-gray-700">
                    {t("userMenu.logout")}
                  </span>
                </div>
              </div>
              <div className="my-4">
                <RepresenteesList
                  // eslint-disable-next-line @typescript-eslint/no-explicit-any
                  contactData={contactData as any}
                  representees={contactData?.representees ?? []}
                  selectedContactId={selectedContactId}
                  setSelectedContact={menuActions.setSelectedContact}
                />
              </div>
              <div className="flex flex-col gap-4 items-center">
                <Menu.Item>
                  {({ close }) => (
                    <div
                      className="flex flex-col gap-1 items-center p-2 hover:bg-gray-100 rounded-md cursor-pointer"
                      onClick={() => {
                        navigateToTransfers();
                        close();
                      }}
                    >
                      <PiHandDeposit className="w-5 h-5 text-gray-600" />
                      <span className="text-xs text-gray-700">
                        {t("userMenu.depositOrWithdraw")}
                      </span>
                    </div>
                  )}
                </Menu.Item>
                <div
                  className="flex flex-col gap-1 items-center p-2 hover:bg-gray-100 rounded-md cursor-pointer"
                  onClick={menuActions.referral}
                >
                  <FaPeopleArrows className="w-5 h-5 text-gray-600" />
                  <span className="text-xs text-gray-700">
                    {t("userMenu.referrals")}
                  </span>
                </div>
              </div>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
      <Modal
        {...depositModalProps}
        header={t("moneyModal.depositModalHeaderRealTime")}
      >
        <DepositModalContent {...depositModalContentProps} />
      </Modal>
      <Modal
        {...bankDepositModalProps}
        header={t("moneyModal.depositModalHeaderBank")}
      >
        <BankDepositModalContent />
      </Modal>
      <ModalV2
        {...userProfileModalProps}
        header={
          <div className="flex gap-2 items-center">
            <GoGear />
            {t("profile.profileHeader")}
          </div>
        }
      >
        <UserProfile {...userProfileModalContentProps} />
      </ModalV2>
      <Modal {...referralModalProps} header={t("userMenu.referrals")}>
        <ReferralModalContent />
      </Modal>
    </>
  );
};
