import { ForwardedRef, forwardRef, HTMLProps } from "react";
import classNames from "classnames";

interface InputProps extends HTMLProps<HTMLInputElement> {
  label?: string;
  error?: string;
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  errorClassName?: string;
}

const InputPlain = (
  {
    label,
    className,
    error,
    startAdornment,
    endAdornment,
    errorClassName,
    ...inputAttributes
  }: InputProps,
  ref: ForwardedRef<HTMLInputElement>
) => (
  <label
    className={classNames("text-base font-normal block relative", {
      "text-red-700": !!error,
    })}
  >
    {label}
    <div className="relative">
      {startAdornment && (
        <div className="absolute top-1/2 left-3 text-gray-500 -translate-y-1/2">
          {startAdornment}
        </div>
      )}
      {endAdornment && (
        <div className="absolute top-1/2 right-3 text-gray-500 -translate-y-1/2">
          {endAdornment}
        </div>
      )}
      <input
        ref={ref}
        className={classNames(
          "h-10 block p-2.5 w-full text-base text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:border-primary-400",
          { "pl-10": !!startAdornment },
          className,
          {
            "text-red-900 placeholder-red-700 bg-red-50 focus:border-red-500 border-red-500":
              !!error,
          }
        )}
        {...inputAttributes}
      />
    </div>
    {error && (
      <span
        className={classNames(
          "block mt-1 text-base text-red-600 relative z-10",
          errorClassName
        )}
      >
        {error}
      </span>
    )}
  </label>
);

export const Input = forwardRef(InputPlain);
