import { Suspense } from "react";
import { LoadingIndicator } from "components";
import { ErrorBoundary } from "components/ErrorBoundary/ErrorBoundary";
import { Outlet } from "react-router-dom";

export const MainLayout = () => {
  return (
    <div className="box-border flex overflow-hidden fixed inset-0 flex-col text-gray-900 bg-gray-50 safe-top safe-bottom">
      <ErrorBoundary>
        <Suspense fallback={<LoadingIndicator center />}>
          <div className="overflow-y-auto flex-1">
            <Outlet />
          </div>
        </Suspense>
      </ErrorBoundary>
    </div>
  );
};
