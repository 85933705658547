import { gql, useQuery } from "@apollo/client";
import { useKeycloak } from "providers/KeycloakProvider";

const USER_INFO_QUERY = gql`
  query GetUserInfo($contactId: Long!) {
    contact(id: $contactId) {
      name
      id
      externalId
      contactId
      language {
        name
      }
      addresses {
        address1
        address2
        city
        country
        electronicCommunication
        email
        marketingCommunication
        phone1
        name
        zipCode
      }
      classification {
        code
        name
      }
      createdDate
      juridical {
        name
        code
      }
      nationality {
        code
        name
      }
      profile {
        id
        attributes {
          stringValue
          attributeKey
        }
      }
      profileDataString
      status
      taxCountry {
        code
        name
      }
      tags
    }
  }
`;

export interface UserInfoQuery {
  contact?: {
    id: string;
    externalId: string;
    contactId: string;
    name: string;
    language: {
      name: string;
    };
    addresses?: {
      address1?: string;
      address2?: string;
      city?: string;
      country?: string;
      electronicCommunication?: boolean;
      email?: string;
      marketingCommunication?: boolean;
      phone1?: string;
      name?: string;
      zipCode?: string;
    }[];
    classification?: {
      code?: string;
      name?: string;
    };
    createdDate?: string;
    juridical?: {
      name?: string;
      code?: string;
    };
    nationality?: {
      code?: string;
      name?: string;
    };
    profile?: {
      id?: string;
      attributes?: {
        stringValue?: string;
        attributeKey?: string;
      }[];
    };
    profileDataString?: string;
    status?: string;
    taxCountry?: {
      code?: string;
      name?: string;
    };
    tags?: string[];
  };
}

export function useGetUserInfo(selectedContactId?: string | number) {
  // TODO: fix this
  const { linkedContact } = useKeycloak();

  const { loading, error, data } = useQuery<UserInfoQuery>(USER_INFO_QUERY, {
    variables: {
      contactId: linkedContact,
    },
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error(
        "Error fetching user info:",
        JSON.stringify(error, null, 2)
      ); // Ensure error is serialized
    },
  });

  const processedData = data?.contact
    ? {
        id: data.contact.id,
        externalId: data.contact.externalId,
        contactId: data.contact.contactId,
        name: data.contact.name,
        language: data.contact.language,
        addresses: data.contact.addresses,
        classification: data.contact.classification,
        createdDate: data.contact.createdDate,
        juridical: data.contact.juridical,
        nationality: data.contact.nationality,
        profile: data.contact.profile,
        profileDataString: data.contact.profileDataString,
        status: data.contact.status,
        taxCountry: data.contact.taxCountry,
        tags: data.contact.tags,
        referralCode:
          data.contact.profile?.attributes?.find(
            (attr) => attr?.attributeKey === "contact.muuttiedot.referral"
          )?.stringValue || null,
        numberOfReferredFriends:
          data.contact.profile?.attributes?.find(
            (attr) =>
              attr?.attributeKey === "contact.muuttiedot.referredfriends"
          )?.stringValue || null,
      }
    : undefined;

  return {
    loading,
    error,
    data: processedData,
  };
}
